import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Img from "gatsby-image";
import { Button, Collapse, Link, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import classNames from "classnames";

const imageSize = 150;

interface Props {
  showAll?: boolean;
}

const Interviews = ({ showAll }: Props) => {
  const [showMore, setShowMore] = useState(false);

  const data = useStaticQuery(graphql`
    query PodcastQuery {
      bac20: file(relativePath: { eq: "Bac20.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      l4r: file(relativePath: { eq: "l4r-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alcoholFreeLife: file(relativePath: { eq: "alcohol-free-life.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tribeSober: file(relativePath: { eq: "tribeSober.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tippingPoint: file(relativePath: { eq: "tippingPoint.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bswk: file(relativePath: { eq: "bswk.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      swt: file(relativePath: { eq: "stories-we-tell.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fiftyandbeyond: file(relativePath: { eq: "50-and-beyond.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yArts: file(relativePath: { eq: "y_arts.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      authorsStory: file(relativePath: { eq: "an_authors_story.webp" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mentalHealthMe: file(relativePath: { eq: "mental_health_me.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const podcasts = [
    {
      title: "The Y Arts Podcast",
      image: data.yArts.childImageSharp.fluid,
      link: "https://podcasts.apple.com/us/podcast/the-y-arts-podcast/id1701140122?i=1000641350235",
      show: true,
    },
    {
      title: "The Sober Living Stories Podcast",
      image: data.authorsStory.childImageSharp.fluid,
      link: "https://thesoberlivingstoriespodcast.buzzsprout.com/2254296/14030672",
      show: true,
    },
    {
      title: "Mental Health & Me",
      image: data.mentalHealthMe.childImageSharp.fluid,
      link: "https://sites.libsyn.com/478539/transformed-by-sobriety-a-conversation-with-author-lisa-may-bennett",
      show: true,
    },
    {
      title: "The Stories We Tell",
      image: data.swt.childImageSharp.fluid,
      link: "https://podcasts.apple.com/podcast-episode/podcast/id1626306009?i=1000618391762&ign-itscg=30200&ign-itsct=lt_p",
      show: showAll,
    },
    {
      title: "To 50 and Beyond",
      image: data.fiftyandbeyond.childImageSharp.fluid,
      link: "https://www.lorimassicot.com/250",
      show: showAll,
    },
    {
      title: "But Seriously, Who Knew?",
      image: data.bswk.childImageSharp.fluid,
      link: "https://podcasts.apple.com/podcast-episode/podcast/id1673560996?i=1000602359105&ign-itscg=30200&ign-itsct=lt_p",
      show: showAll,
    },
    {
      title: "Alcohol Free Life",
      image: data.alcoholFreeLife.childImageSharp.fluid,
      link: "https://podcasts.apple.com/us/podcast/alcohol-free-life-janey-lee-grace/id1448715889?i=1000579682092",
      show: showAll,
    },
    {
      title: "Alcohol Tipping Point",
      image: data.tippingPoint.childImageSharp.fluid,
      link: "https://podcasts.apple.com/us/podcast/unfurling-from-anxiety-self-doubt-and-drinking/id1553157460?i=1000565607652",
      show: showAll,
    },
  ];

  const morePodcasts = [
    {
      title: "Getting BAC2zero",
      image: data.bac20.childImageSharp.fluid,
      link: "https://fb.watch/imldgvqiiX/",
    },
    {
      title: "Listen for REAL",
      image: data.l4r.childImageSharp.fluid,
      link: "https://www.listenforreal.com/grey-area-drinking-and-other-honest-admissions/",
    },
    {
      title: "Tribe Sober",
      image: data.tribeSober.childImageSharp.fluid,
      link: "https://podcasts.apple.com/us/podcast/my-unfurling-with-lisa-bennett/id1513124200?i=1000575949712",
    },
  ];

  interface Podcast {
    title: string;
    image: any;
    link: string;
  }

  const PodcastLink = ({ podcast }: { podcast: Podcast }) => {
    const { title, image, link } = podcast;

    return (
      <div
        className="flex flex-col"
        style={{ maxWidth: imageSize, minWidth: imageSize }}
      >
        <Link target="_blank" rel="noopener" href={link} underline="none">
          <div className="pb-4">
            <Img fluid={image} />
          </div>
          <div>
            <div className="flex-col pb-4 text-center text-gray-900">
              <Typography variant="body1">{title}</Typography>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div>
      <section className="p-10">
        <div id="interviews" className="text-center pb-8">
          <Typography className="text-6xl" fontFamily={"Billy Ohio Dua"}>
            Interviews
          </Typography>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col items-center">
            <div
              className={classNames(
                "grid md:grid-cols-2 justify-center gap-10 auto-rows-max",
                { "lg:grid-cols-3": !showAll, "lg:grid-cols-4": showAll }
              )}
            >
              {podcasts.map(
                (podcast) => podcast.show && <PodcastLink podcast={podcast} />
              )}
              {morePodcasts.map((podcast) => (
                <Collapse in={showMore}>
                  <PodcastLink podcast={podcast} />
                </Collapse>
              ))}
            </div>
            {showAll && (
              <Button
                className={classNames("normal-case", {
                  "-mt-24 lg:-mt-9": !showMore,
                })}
                variant="text"
                endIcon={
                  !showMore ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )
                }
                onClick={() => setShowMore((current) => !current)}
              >
                {!showMore ? "More" : "Less"}
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Interviews;
