import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import { faHeadphonesSimple } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const Playlists = () => {
  return (
    <div>
      <section className="p-10">
        <div id="blog" className="text-center pb-8">
          <Typography className="text-6xl" fontFamily={"Billy Ohio Dua"}>
            My Unfurling playlist
          </Typography>
        </div>
        <div className="flex flex-col items-center flex-wrap md:flex-nowrap justify-center gap-4">
          <Typography className="text-lg">
            Songs inspired by the themes, stories, and people in{" "}
            <em>
              My Unfurling: Emerging from the Grip of Anxiety, Self-Doubt, and
              Drinking
            </em>
          </Typography>
          <div className="flex gap-8 items-center">
            <div className="rotate-2 pr-5">
              <FontAwesomeIcon
                className="text-gray-700 h-6 w-6"
                icon={faMusic}
              />
            </div>
            <div>
              <Tooltip title="Apple Music">
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://music.apple.com/us/playlist/my-unfurling/pl.u-NPzmFp1L13R"
                  underline="none"
                >
                  <FontAwesomeIcon
                    className="h-14 w-14 text-gray-700"
                    icon={faApple}
                  />
                </Link>
              </Tooltip>
            </div>
            <div className="relative top-[3px]">
              <Tooltip title="Spotify">
                <Link
                  className="text"
                  target="_blank"
                  rel="noopener"
                  href="https://open.spotify.com/playlist/5JJsX8HL446lAFzEoX19Wz?si=b7357d92ac054840&nd=1"
                  underline="none"
                  color="inherit"
                >
                  <FontAwesomeIcon
                    className="h-12 w-12 text-gray-700"
                    icon={faSpotify}
                  />
                </Link>
              </Tooltip>
            </div>
            <div className="pl-5">
              <FontAwesomeIcon
                className="text-gray-700 h-6 w-6"
                icon={faHeadphonesSimple}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Playlists;
