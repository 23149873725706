import React from "react";
import { Link, Typography } from "@mui/material";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import Reviews from "./Reviews";
import Playlists from "./Playlists";
import Interviews from "./Interviews";

const MyUnfurling = () => {
  const data = useStaticQuery(graphql`
    query MyUnfurlingImageQuery {
      file(relativePath: { eq: "my_unfurling.webp" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <section className="p-14" id="latest-book">
        <div className="flex flex-wrap-reverse lg:flex-nowrap justify-center items-center gap-10">
          <div style={{ maxWidth: 750 }}>
            <div className="flex flex-col items-center gap-5">
              <div className="text-center pb-6">
                <Typography className="text-6xl" fontFamily={"Billy Ohio Dua"}>
                  My Unfurling
                </Typography>
              </div>
              <Typography className="text-center" variant="h6">
                Emerging from the Grip of Anxiety, Self-Doubt, and Drinking
              </Typography>
              <div className="flex flex-col gap-4">
                <p className="text-left">
                  After years of questioning her relationship with alcohol, Lisa
                  May Bennett decided to quit drinking. She never hit a dramatic
                  rock bottom, but her main mood enhancer had lost its luster.
                  She felt stuck, and she suspected that a more fulfilling life
                  awaited her in sobriety. With booze out of the way, Lisa began
                  what she dubbed her "unfurling." She was alive, hungry, and
                  happy. And she was writing again.
                </p>
                <p className="text-left">
                  In <em>My Unfurling</em>, Lisa examines how self-doubt and
                  anxiety led her to drink. She explores her complicated
                  relationship with her mother, the "casual bullying" that came
                  with being a late bloomer, her intense friendships, and her
                  search for validation through love and work.
                </p>
                <p className="text-left">
                  <em>My Unfurling</em> traces Lisa's journey beyond insecurity,
                  jealousy, and fear. It is the story of her grief over losing a
                  friend to addiction and her struggle to gain control over her
                  own mind. In this touching and funny memoir, an engaging,
                  relatable voice shares how she broke free from a tangle of bad
                  habits and her plan to keep reaching for the light.
                </p>
                <p className="text-left">
                  Anyone who is wondering when or if they'll ever burst out and
                  follow their dreams will find <em>My Unfurling</em> compelling
                  and hopeful.
                </p>
                <p className="flex-col text-left text-sm">
                  "Reading <em>My Unfurling</em> is like taking a road trip with
                  a smart, funny friend whose stories about her life
                  effortlessly help you to understand and appreciate your own.
                  Lisa May Bennett doesn't shy away from darkness and difficult
                  truths, but hers is a fundamentally optimistic voice that
                  gives sober-curious readers not just any path forward, but one
                  ablaze with light and possibility. I loved it."
                  <blockquote>
                    <span className="font-semibold text-sm">
                      —Kristi Coulter, author of{" "}
                      <em>Nothing Good Can Come from This</em>
                    </span>
                  </blockquote>
                </p>
                <div>
                  <p className="flex-col text-left text-sm">
                    "<em>My Unfurling</em> is a well-written memoir about gray
                    area drinking. Check it out!"
                    <blockquote>
                      <span className="font-semibold text-sm">
                        —Jolene Park, leading authority on gray area drinking
                      </span>
                    </blockquote>
                  </p>
                </div>
                <div className="flex-col text-left text-sm">
                  <p>
                    "I thoroughly enjoyed reading and relating to the words on
                    every page of <em>My Unfurling</em>...a must 'quit lit' read
                    and then some."
                    <blockquote>
                      <span className="font-semibold text-sm">
                        —Peggi Cooney, author of <em>This Side of Alcohol</em>{" "}
                        and founder of the This Side of Alcohol Facebook group
                      </span>
                    </blockquote>
                  </p>
                </div>
                <div className="flex-col text-center">
                  Now available on audio!
                </div>
              </div>
              {/* <div>Purchase now: </div> */}
              <div className="flex flex-wrap gap-2 justify-center">
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://mybook.to/myunfurling"
                  underline="none"
                  color="inherit"
                >
                  <Button
                    className="bg-yellow-400 text-black"
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faAmazon} />}
                  >
                    Order Now
                  </Button>
                </Link>
                {/* {bookLinks.map((bl, i, { length }) => (
                  <div
                    key={bl.title}
                    className={classNames("after:text-gray-500 after:pl-2", {
                      "after:content-['|']": length - 1 !== i,
                    })}
                  >
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={bl.link}
                      underline="none"
                    >
                      {bl.title}
                    </Link>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
          <div
            style={{
              width: 400,
              minWidth: 200,
              position: "relative",
              left: "-47px",
            }}
          >
            <Img fluid={data.file.childImageSharp.fluid} alt="My Unfurling" />
          </div>
        </div>
      </section>
      <section className="bg-yellow-400">
        <Reviews />
      </section>
      <section>
        <Interviews showAll />
      </section>
      <section className="bg-yellow-400">
        <Playlists />
      </section>
    </div>
  );
};

export default MyUnfurling;
