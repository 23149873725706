import React from "react";
import { Typography } from "@mui/material";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const Reviews = () => {
  return (
    <div>
      <section className="p-14" id="reviews">
        <div className="flex justify-center items-center w-60 sm:w-full m-auto  gap-20 pb-4 text-center">
          <Typography className="text-6xl" fontFamily={"Billy Ohio Dua"}>
            More praise for My Unfurling
          </Typography>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 md:grid-cols-3 justify-center items-center gap-6 max-w-7xl">
            <div>
              <p className="text-center p-6">
                "I found <em>My Unfurling</em> to be engaging from the first
                sentence." - SUE
              </p>
              <p className="text-center">
                "I laughed and cried. I also found passages that I would like to
                copy and keep with me to keep inspiring me to evolve and move
                forward in a positive way." - LAURA
              </p>
            </div>
            <div>
              <p className="text-center p-6">
                "I love Lisa's writing style; it was like she was speaking to
                me." - SHARON
              </p>
              <p className="text-center">
                "I thought <em>My Unfurling</em> was really beautiful, and there
                were many moments within it that I found myself relating to
                Lisa's experiences." - MERRILL
              </p>
            </div>
            <div>
              <p className="text-center p-6">
                "I loved <em>My Unfurling</em>! I read it in one sitting. There
                is an earnestness and raw honesty to Lisa's inquiry and
                writing." - ERIN
              </p>
              <p className="text-center">
                "I was totally hooked each time that I’d sit down to read{" "}
                <em>My Unfurling</em>. It was powerful to read such a vulnerable
                piece." - MARY
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reviews;
